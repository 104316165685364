// body
& {
	font-size: 14px;
}

// paragraph
p {
	min-height: 1rem;
}

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s;
}

// form
.form {
	padding: 15px;

	@include media-breakpoint-up(xl) {
		padding: 30px;
	}

	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// default-card
.default-card {
	padding: 10px;
	border: none;
	border-radius: 0;
	box-shadow: $shadow;

	.card-body {
		.card-caption {
			.card-title {
				margin-bottom: 0;
				color: $green;

				&::after {
					content: "\f178";
					margin-left: 10px;
					font-size: smaller;
					font-family: $font-awesome;
				}

				&:hover {
					color: $green-dark;
				}
			}

			.card-subtitle {
				margin: 0;
				font-size: 14px;
				font-weight: 700;
				font-family: $font-family-primary;
			}

			.card-description {
				margin-top: 10px;
				padding-top: 10px;
				border-top: 1px solid $green;
			}
		}
	}

	&:hover {
		.card-title {
			color: $green-dark;
		}
	}
}

// page-title
.page-title {
	h1 {
		margin-bottom: 15px;

		&::after {
			content: "";
			display: block;
			width: 65px;
			height: 16px;
			margin: 10px auto 0 auto;
			background: url('/images/title-divider.png');
		}
	}
}

// accommodation-objects
.accommodation-objects {
	.accommodation-objects-holder {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -5px;

		.card-accommodation-object {
			margin: 5px;

			.card-image,
			.card-body {
				display: none;
			}
		}
	}
}
