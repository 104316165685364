// header
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	box-shadow: $shadow;

	.container,
	.container-fluid {
		padding: 0;

		.container-holder {
			margin-left: 0;
			margin-right: 0;

			>.column {
				padding-left: 0;
				padding-right: 0;

				@extend .align-items-center;

				@include media-breakpoint-down(lg) {
					flex-flow: row wrap;
				}

				@extend .navbar-expand-xl;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		margin-left: auto;

		.navbar-toggler {
			display: flex;
			align-items: center;
			padding: 0 15px;
			border: none;
			background-color: $green;
			border-radius: 0;
			color: $white;
			font-size: 16px;
			font-weight: 700;
			line-height: 50px;
			transition: color 0.5s;

			&:hover {
				background-color: $green-dark;
			}

			i {
				margin-right: 10px;
			}

			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		z-index: 100;
		position: absolute;
		top: 15px;
		left: 15px;

		@include media-breakpoint-up(xl) {
			top: 20px;
			left: 30px;
		}

		a {
			@extend .d-block;
			width: 180px;
			padding: 10px 15px;
			background-color: #fff;
			border: 1px solid #e2eecd;
			box-shadow: $shadow;

			@include media-breakpoint-up(xl) {
				width: 275px;
				padding: 15px 30px;
			}

			img {
				@extend .w-100;
			}
		}
	}

	&.sticky {
		.logo {
			a {
				width: 180px;
				padding: 10px 15px;
			}
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 3;

			.navbar-nav {
				margin-top: 80px;
				padding: 15px;
				border-bottom: 1px solid rgba($green, 0.15);

				>.nav-item {
					border-top: 1px solid rgba($green, 0.15);

					>.nav-link {
						display: flex;
						align-items: center;
						color: $green-dark;
						font-size: 15px;

						.menu-icon {
							margin-right: 10px;
						}

						&:hover {
							color: $green;
						}
					}

					&.active {
						>.nav-link {
							color: $green;
						}
					}
				}

				.dropdown-menu {
					position: static !important;
					transform: none !important;
					margin: 0 0 0 30px;
					padding: 0;
					border: none;
					box-shadow: none;
					background: transparent;

					.nav-item {
						.nav-link {
							color: $green-dark;
							font-size: 14px;

							&:hover {
								color: $green;
							}

							&:first-of-type {
								.nav-link {
									padding-top: 0;
								}
							}
						}

						&.active {
							.nav-link {
								color: $green;
							}
						}
					}
				}
			}
		}

		@include media-breakpoint-up(xl) {
			.navbar-nav {
				margin-left: auto;
				align-items: center;

				>.nav-item {
					margin-left: 35px;
					font-size: 15px;
					text-align: center;

					>.nav-link {
						padding: 0 !important;
						color: $green-dark;

						.menu-icon {
							display: block;
						}

						&:hover {
							color: $green;
						}
					}

					&.active {
						>.nav-link {
							color: $green;
						}
					}

					&.last {
						.nav-link {
							padding: 0 30px !important;
							background-color: $green;
							color: $white;
							line-height: 90px;

							.menu-icon {
								display: none;
							}

							&:hover {
								background-color: $green-dark;
							}
						}

						&.active {
							.nav-link {
								background-color: $green-dark;
							}
						}
					}
				}

				.nav-item {
					.dropdown-menu {
						margin-top: 10px;
						padding: 1rem;
						border-radius: 0;

						.nav-item {
							font-size: 15px;
							line-height: 1.2;
							white-space: nowrap;

							.nav-link {
								color: $green-dark;

								&:hover {
									color: $green;
								}
							}

							&.active {
								.nav-link {
									color: $green;
								}
							}
						}
					}
				}
			}
		}
	}
}

// home
&.home {
	.header {
		@include media-breakpoint-up(xl) {
			position: fixed;
		}
	}
}
