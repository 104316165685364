// bg-*
// =========================================================================
section {

	&.bg-light,
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

.lead-section+.content-section,
.lead-section+.bundle-overview-section {
	margin-top: calc(-6vh + 30px);
}

// lead-section
// =========================================================================
.lead-section {
	margin: 6vh 0;
	font-size: 16px;

	.container-one-column {
		.container-holder {
			max-width: 960px;
			margin: 0 auto;
			text-align: center;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {

		// title
		.title {
			@extend .page-title;
		}

		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}
		}

		// assortiment
		.former-price {
			font-size: $font-size-sm;
			text-decoration: line-through;
		}

		.discount-price {
			font-size: $font-size-sm;

			.amount {
				font-size: $font-size-lg;
			}

			margin-bottom: 1rem;
		}

		.price {
			margin-bottom: 1rem;

			.amount {
				font-size: $font-size-lg;
			}
		}

	}
}

&.home {
	.lead-section {
		position: relative;
		margin: 0;
		padding: 8vh 0;
		overflow: hidden;

		.visual-tree {
			z-index: -1;
			position: absolute;
			bottom: 0;
			width: 15vw;

			&.left {
				left: 0;
				max-width: 229px;
				max-height: 360px;
			}

			&.right {
				right: 0;
				max-width: 228px;
				max-height: 356px;
			}
		}

		.wysiwyg {
			.btn {
				margin: 5px;
			}
		}
	}
}

// content-section
// =========================================================================
.content-section {
	margin: 6vh 0;

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// collection-section
// =========================================================================
.collection-section {
	margin: 6vh 0;
	border-bottom: 2px solid $white;

	&.bg-visual {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: url('/images/lines.svg') no-repeat bottom center;
			background-size: contain;
		}
	}

	// collection
	.collection {
		margin-top: 30px;

		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}

					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}

					.card {
						@extend .default-card;
					}
				}
			}
		}

		&.slider {
			.owl-stage-outer {
				margin-left: -10px;
				margin-right: -10px;

				.owl-stage {
					padding: 0 10px 10px 10px;
				}
			}

			.card {
				@extend .default-card;

				.card-body {
					.card-caption {
						text-align: center;

						.card-title {
							margin-bottom: 0;
						}

						.card-description {
							border-top: none;
							margin-top: 0;
							padding-top: 0;
						}
					}
				}
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {

	// owl-carousel
	.owl-carousel {
		height: 75vh;
		min-height: 300px;
		max-height: 600px;

		.item {
			height: 75vh !important; // overide
			min-height: 300px;
			max-height: 600px;
			padding: unset; // overide

			.owl-container {
				position: relative;
				max-width: 860px;

				.owl-caption {
					padding: 30px 5%;
					background: rgba($white, 0.8);
					color: $green-dark;
					text-shadow: none;

					.owl-title {
						@include font-size($h2-font-size);
						margin-bottom: 15px;

						&::after {
							content: "";
							display: block;
							width: 65px;
							height: 16px;
							margin: 10px auto 0 auto;
							background: url('/images/title-divider.png');
						}
					}
				}
			}
		}

		.owl-dots {
			.owl-dot {
				span {
					width: 50px;
					height: 5px;
					border: none;
					border-radius: 0;
					background-color: $green;
					box-shadow: none;
				}

				&:hover,
				&.active {
					span {
						background-color: $yellow;
					}
				}
			}
		}

		.owl-nav {
			display: none;
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				justify-content: flex-start;
			}
		}

		.card {
			@extend .default-card !optional;

			.card-body {
				.card-caption {
					.card-title {
						color: $green-dark;

						&:hover {
							color: $green;
						}
					}

					.card-subtitle {
						color: $green;
					}
				}
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// gallery-section
// =========================================================================
.gallery-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	margin: 6vh 0;

	h2 {
		margin-bottom: 30px;
	}
}

.image-collection {
	.gallery-caption {
		display: none;
	}

	.gallery-grid .gallery-item {
		flex: 0 0 33%;
		max-width: 33%;
	}
}
