// mini-sab
.mini-sab {
	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: -30px 0 0 0;
			padding: 15px 0;
			border-radius: 4px;
			background: $green;
			box-shadow: $shadow;
			color: #fff;

			@include media-breakpoint-up(lg) {
				margin-top: -50px;
			}
		}
	}
}
