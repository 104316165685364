// footer
.footer {

	// social-bar
	.social-bar {
		display: none;
		z-index: 997;
		position: fixed;
		top: 50%;
		right: 1px;
		transform: translateY(-50%);

		ul {
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin-bottom: 1px;

				a {
					display: block;
					width: 40px;
					background-color: $yellow;
					color: $white;
					font-size: 18px;
					line-height: 40px;
					text-align: center;

					.list-item-title {
						display: none;
					}

					&:hover {
						background-color: $green-dark;
						color: $white;
					}
				}
			}
		}
	}

	// footer-partners
	.footer-partners {
		padding: 6vh 0;

		ul {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				margin: 15px;
			}
		}
	}

	// footer-sitelinks
	.footer-sitelinks {
		position: relative;
		padding-top: 8vh;
		padding-bottom: calc(8vh + 184px);
		background: url('/images/trees.svg') no-repeat center center $green;
		background-size: contain;

		@include media-breakpoint-down(xs) {
			padding-top: 6vh;
			padding-bottom: calc(8vh + 30px);
		}

		@include media-breakpoint-up(xl) {
			background-size: cover;
		}

		text-align: center;

		.container {
			max-width: 960px;
		}

		.collection {
			&.grid {
				.grid-items {
					.item {
						@include media-breakpoint-down(xs) {
							@include make-col(6);
						}

						@include media-breakpoint-up(lg) {
							@include make-col(3);
						}

						@include media-breakpoint-up(xl) {
							@include make-col(3);
						}

						.card {
							background: none;
							border: none;
							border-radius: 0;
							color: $white;

							.card-image {
								font-size: 36px;

								@include media-breakpoint-up(xl) {
									font-size: 48px;
								}
							}

							.card-body {
								padding: 0;

								.card-caption {
									color: $white;

									.card-title {
										margin-bottom: 0;
										font-size: 24px;

										&:hover {
											color: $green-dark;
										}
									}
								}
							}

							&:hover {
								color: $green-dark;
							}
						}
					}
				}
			}
		}

		.visual-green-hills {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	// footer-info
	.footer-info {
		position: relative;
		padding: 15px 0 45px 0;
		background-color: $green-dark;
		color: $white;

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			color: $white;
			font-weight: 700;
			font-family: $font-family-primary;
		}

		a {
			color: $white;
			text-decoration: none;

			&:hover {
				color: $green;
			}
		}

		// footer-logolink
		.footer-logolink {
			margin-top: 15px;

			ul {
				li {
					margin-right: 15px;
					font-size: 24px;
				}
			}
		}

		// copyright-links
		.copyright-links {
			margin-top: auto;

			ul {
				display: flex;
				flex-wrap: wrap;
				@include media-breakpoint-down(sm) {
					flex-direction: column;
				}
				margin: 0;
				padding: 0;
				list-style: none;

				li {
					margin-right: 25px;
				}
			}
		}
	}
}
