// eyecatcher
.eyecatcher,
.eyecatcher .owl-carousel .item {
	height: 50vh;
	min-height: 300px;
	max-height: 540px;
}

.eyecatcher.large,
.eyecatcher.large .owl-carousel .item {
	height: 75vh;
	min-height: 300px;
	max-height: 1080px;

	@include media-breakpoint-up(sm) {
		height: calc(100vh - 100px);
		min-height: 400px;
	}
}

// eyecatcher
.eyecatcher {
	.owl-carousel {
		.item {
			.owl-container {
				z-index: 1;
				position: relative;

				.owl-caption {
					.owl-title {
						margin-bottom: 0;
						@include font-size($h2-font-size);
					}

					.owl-subtitle {
						margin-bottom: 0;
						@include font-size($h1-font-size);

						@include media-breakpoint-up(xl) {
							font-size: 60px;
						}
					}
				}
			}
		}
	}

	// owl
	.owl-nav,
	.owl-dots {
		display: none;
	}
}

&.home {
	.eyecatcher {
		.owl-carousel {
			.item {
				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: url('/images/lines.svg') no-repeat bottom center;
					background-size: contain;
				}
			}
		}
	}
}
